<template>
  <v-container style="height: 100vh; max-height: 100vh;" class="private-background pa-16 mr-5" id="home">
    <p dir="ltr"
       style="line-height:1.2;text-align: center;margin-top:5pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conditions g&eacute;n&eacute;rales d&rsquo;utilisation de l&apos;application PWA Private-Games</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En vigueur au 28 février 2022</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:-4pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 1 : Objet</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les pr&eacute;sentes CGU ou Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation encadrent juridiquement les modalit&eacute;s de mise &agrave; disposition des services et &nbsp;de l&apos;application PWA private-games.com (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; Private-Games&raquo;), fournis par CBJ M&eacute;dia.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les pr&eacute;sentes CGU sont accessibles &agrave; la rubrique &ldquo;CGU&rdquo; de l&rsquo;application PWA Private-Games</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CBJ M&eacute;dia se r&eacute;serve le droit de proc&eacute;der &agrave; des modifications des CGU sans avoir &agrave; en informer les utilisateurs ni &agrave; solliciter leur accord.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 2 : Mentions l&eacute;gales</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;dition du site Private-Games est assur&eacute;e par la soci&eacute;t&eacute; CBJ M&eacute;dia, au capital de 5000 euros, inscrite sous le num&eacute;ro 8</span><span
        style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">93 668 152 RCS de Besan&ccedil;on, N</span><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&deg; de TVA intracommunautaire : FR 31893668152</span><span
        style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;h&eacute;bergeur de l&rsquo;application PWA Private-Games.fr est la soci&eacute;t&eacute; OVH avec le site Internet www.ovh.com</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 3 : Acc&egrave;s &agrave; l&rsquo;application PWA</span>
    </p>
    <p dir="ltr"
       style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;application PWA Private-Games permet d&rsquo;acc&eacute;der gratuitement aux services suivants :</span>
    </p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li aria-level="1" dir="ltr"
          style="list-style-type:disc;font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">des d&eacute;fis et sc&eacute;narios &eacute;rotiques sur diff&eacute;rents th&egrave;mes se jouant seul ou en couple</span>
        </p>
      </li>
      <li aria-level="1" dir="ltr"
          style="list-style-type:disc;font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">le partage et l&rsquo;&eacute;change de jeux entre joueurs&nbsp;</span>
        </p>
      </li>
      <li aria-level="1" dir="ltr"
          style="list-style-type:disc;font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:5pt;"><span
            style="font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">des fonctionnalit&eacute;s de discussion, de partage et d&rsquo;&eacute;change</span>
        </p>
      </li>
    </ul>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;application PWA est accessible gratuitement depuis n&rsquo;importe o&ugrave; par tout utilisateur disposant d&rsquo;un acc&egrave;s &agrave; Internet. Tous les frais n&eacute;cessaires pour l&rsquo;acc&egrave;s aux services (mat&eacute;riel informatique, connexion Internet&hellip;) sont &agrave; la charge de l&rsquo;utilisateur.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;acc&egrave;s aux services d&eacute;di&eacute;s aux membres s&rsquo;effectue &agrave; l&rsquo;aide d&rsquo;un identifiant et d&rsquo;un mot de passe.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Nous ne sommes l&eacute;galement tenus &agrave; aucune obligation g&eacute;n&eacute;rale de surveillance du contenu transmis ou stock&eacute; via et sur Private-Games</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dans le cadre de l'utilisation gratuite, l’Utilisateur est limité à 4 parties simultanées.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L’Utilisateur est limité à 3 envois de défis par jour. Une partie est limitée dans le temps.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L’Utilisateur peut utiliser ses propres points, désignés “pimentos” pour débloquer du contenu. </span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L’Utilisateur peut utiliser ses propres points, désignés “pimentos” pour acheter certains produits de la boutique de l’application, marqués et désignés par un paiement en “pimentos”.  </span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tout contenu publi&eacute; &agrave; partir d&apos;un compte engage la responsabilit&eacute; de son possesseur.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&apos;acc&egrave;s &agrave; Private-Games est ouvert &agrave; toutes les personnes ayant atteint l&apos;&acirc;ge l&eacute;gal de la majorit&eacute; dans leur pays et disposant d&apos;un courriel valide.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pour des raisons de maintenance ou autres, l&rsquo;acc&egrave;s &agrave; l&rsquo;application PWA peut &ecirc;tre interrompue ou suspendue par l&rsquo;&eacute;diteur sans pr&eacute;avis ni justification, et ne peut &ecirc;tre tenue responsable de tout dommage r&eacute;sultant d&apos;une indisponibilit&eacute; de Private-Games, et ce, quelle qu&apos;en soit la nature.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 4 : Collecte des donn&eacute;es</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pour la cr&eacute;ation du compte de l&rsquo;Utilisateur, la collecte des informations au moment de l&rsquo;inscription sur le site est n&eacute;cessaire et obligatoire. Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier relative &agrave; l&rsquo;informatique, aux fichiers et aux libert&eacute;s, la collecte et le traitement d&rsquo;informations personnelles s&rsquo;effectuent dans le respect de la vie priv&eacute;e.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Suivant la loi Informatique et Libert&eacute;s en date du 6 janvier 1978, articles 39 et 40, l&rsquo;Utilisateur dispose du droit d&rsquo;acc&eacute;der, de rectifier, de supprimer et d&rsquo;opposer ses donn&eacute;es personnelles. L&rsquo;exercice de ce droit s&rsquo;effectue par :</span>
    </p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li aria-level="1" dir="ltr"
          style="list-style-type:disc;font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
            style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le formulaire de contact ;</span>
        </p>
      </li>
      <li aria-level="1" dir="ltr"
          style="list-style-type:disc;font-size:11pt;font-family:Nunito;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
        <p dir="ltr"
           style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;">
          <span
              style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Son espace client.</span>
        </p>
      </li>
    </ul>
    <br/>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 5 : Propri&eacute;t&eacute; intellectuelle</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les marques, logos ainsi que les contenus du site Private-Games (illustrations graphiques, textes&hellip;) sont prot&eacute;g&eacute;s par le Code de la propri&eacute;t&eacute; intellectuelle et par le droit d&rsquo;auteur.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La reproduction et la copie des contenus par l&rsquo;Utilisateur requi&egrave;rent une autorisation pr&eacute;alable du site. Dans ce cas, toute utilisation &agrave; des usages commerciaux ou &agrave; des fins publicitaires est proscrite.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 6 : Responsabilit&eacute;</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bien que les informations publi&eacute;es sur le site soient r&eacute;put&eacute;es fiables, le site se r&eacute;serve la facult&eacute; d&rsquo;une non-garantie de la fiabilit&eacute; des sources.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les informations diffus&eacute;es sur le site Private-Games sont pr&eacute;sent&eacute;es &agrave; titre purement informatif et sont sans valeur contractuelle. En d&eacute;pit des mises &agrave; jour r&eacute;guli&egrave;res, la responsabilit&eacute; du site ne peut &ecirc;tre engag&eacute;e en cas de modification des dispositions administratives et juridiques apparaissant apr&egrave;s la publication. Il en est de m&ecirc;me pour l&rsquo;utilisation et l&rsquo;interpr&eacute;tation des informations communiqu&eacute;es sur la plateforme.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le site d&eacute;cline toute responsabilit&eacute; concernant les &eacute;ventuels virus pouvant infecter le mat&eacute;riel informatique de l&rsquo;Utilisateur apr&egrave;s l&rsquo;utilisation ou l&rsquo;acc&egrave;s &agrave; ce site.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le site ne peut &ecirc;tre tenu pour responsable en cas de force majeure ou du fait impr&eacute;visible et insurmontable d&rsquo;un tiers.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La garantie totale de la s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es n&rsquo;est pas assur&eacute;e par le site. Cependant, le site s&rsquo;engage &agrave; mettre en &oelig;uvre toutes les m&eacute;thodes requises pour le faire au mieux.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:5pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 7 : Liens hypertextes</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le site peut &ecirc;tre constitu&eacute; de liens hypertextes. En cliquant sur ces derniers, l&rsquo;Utilisateur sortira de la plateforme. Cette derni&egrave;re n&rsquo;a pas de contr&ocirc;le et ne peut pas &ecirc;tre tenue responsable du contenu des pages web relatives &agrave; ces liens.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 8</span><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;:&nbsp;</span><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookies</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lors des visites sur le site, l&rsquo;installation automatique d&rsquo;un cookie sur le logiciel de navigation de l&rsquo;Utilisateur peut survenir.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les cookies correspondent &agrave; de petits fichiers d&eacute;pos&eacute;s temporairement sur le disque dur de l&rsquo;ordinateur de l&rsquo;Utilisateur. Ces cookies sont n&eacute;cessaires pour assurer l&rsquo;accessibilit&eacute; et la navigation sur le site. Ces fichiers ne comportent pas d&rsquo;informations personnelles et ne peuvent pas &ecirc;tre utilis&eacute;s pour l&rsquo;identification d&rsquo;une personne.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;information pr&eacute;sente dans les cookies est utilis&eacute;e pour am&eacute;liorer les performances de navigation sur l&rsquo;application PWA Private-Games</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En naviguant sur le site, l&rsquo;Utilisateur accepte les cookies. Leur d&eacute;sactivation peut s&rsquo;effectuer via les param&egrave;tres du logiciel de navigation.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;">
      &nbsp;</p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 9 : Publication par l&rsquo;Utilisateur</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;application PWA Private-Games permet aux membres de publier des jeux, du contenu, des commentaires.</span>
    </p>
    <p dir="ltr"
       style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 5pt 0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dans ses publications, le membre est tenu de respecter les r&egrave;gles de la Netiquette ainsi que les r&egrave;gles de droit en vigueur.</span>
    </p>
    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:5pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le site dispose du droit d&rsquo;exercer une mod&eacute;ration &agrave; priori sur les publications et peut refuser leur mise en ligne sans avoir &agrave; fournir de justification.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tout membre Private-Games d&eacute;clare accepter qu&apos;en publiant du contenu sur l&rsquo;espace communaut&eacute; de l&apos;application PWA Private-Games, il accorde &agrave; CBJ M&eacute;dia une licence gratuite sur ce contenu qui permet de le rendre consultable &agrave; d&apos;autres membres ou visiteurs.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Il autorise &eacute;galement CBJ M&eacute;dia d&rsquo;adapter le format dudit contenu et &agrave; le reproduire sur le site, dans le cadre strict des fonctionnalit&eacute;s de l&rsquo;application PWA Private-Games.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Toute publication sur le site implique la d&eacute;l&eacute;gation du droit de propri&eacute;t&eacute; non exclusif et gratuit &agrave; la soci&eacute;t&eacute; &eacute;ditrice de repr&eacute;senter, reproduire, modifier, adapter, distribuer et diffuser la publication n&rsquo;importe o&ugrave; et sur n&rsquo;importe quel support pour la dur&eacute;e de la propri&eacute;t&eacute; intellectuelle. Cela concerne notamment le droit d&rsquo;utilisation de la publication sur le web et sur les r&eacute;seaux de t&eacute;l&eacute;phonie mobile.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&Agrave; chaque utilisation, l&rsquo;&eacute;diteur s&rsquo;engage &agrave; mentionner le nom du membre &agrave; proximit&eacute; de la publication.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Utilisateur est tenu responsable de tout contenu qu&rsquo;il met en ligne. L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas publier de contenus susceptibles de porter atteinte aux int&eacute;r&ecirc;ts de tierces personnes. Toutes proc&eacute;dures engag&eacute;es en justice par un tiers l&eacute;s&eacute; &agrave; l&rsquo;encontre du site devront &ecirc;tre prises en charge par l&rsquo;Utilisateur.</span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La suppression ou la modification par le site du contenu de l&rsquo;Utilisateur peut s&rsquo;effectuer &agrave; tout moment, pour n&rsquo;importe quelle raison et sans pr&eacute;avis.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 11 : Dur&eacute;e du contrat</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le pr&eacute;sent contrat est valable pour une dur&eacute;e ind&eacute;termin&eacute;e. Le d&eacute;but de l&rsquo;utilisation des services du site marque l&rsquo;application du contrat &agrave; l&rsquo;&eacute;gard de l&rsquo;Utilisateur.</span>
    </p>
    <br/>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span
        style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Article 12 : Droit applicable et juridiction comp&eacute;tente</span>
    </p>
    <br/>
    <p dir="ltr"
       style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;">
      <span
          style="font-size:11pt;font-family:Nunito;color:#333333;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le pr&eacute;sent contrat est soumis &agrave; la l&eacute;gislation fran&ccedil;aise. L&rsquo;absence de r&eacute;solution &agrave; l&rsquo;amiable des cas de litige entre les parties implique le recours aux tribunaux fran&ccedil;ais comp&eacute;tents pour r&eacute;gler le contentieux.</span>
    </p>
  </v-container>
</template>

<script>
export default {
  name: "CGU"
}
</script>

<style scoped>
h1 {
  font-family: "Nunito";
  font-weight: bolder;
}

.private-background {
  background-image: url("~@/assets/background/background-pg-xl-white.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9e6df;
  height: calc(100vh - 64px);
  width: 100vw;
  min-width: 100vw;
  overflow-y: auto;
}
</style>